const teacher_platform = "讲师端";
const student_platform = "学员端";
const ExecPath = process.execPath;
const isT = ExecPath.indexOf(teacher_platform) >= 0;
const isS = ExecPath.indexOf(student_platform) >= 0;

export const toParse = (str) => {
  return JSON.parse(str);
};

export const Stringify = (o) => JSON.stringify(o);

export const getLoginTocken = () =>
  sessionStorage.getItem("access_token") ||
  localStorage.getItem("access_token");

export const Isphone = function (phoneNum) {
  return /^1[3|4|5|7|8][0-9]{9}$/.test(phoneNum);
};

export const Session = {
  setSession: (key, val) => localStorage.setItem(key, val),
  getSession: (key) => localStorage.getItem(key),
  clearSession: () => localStorage.clear(),
  removeItem: (key) => localStorage.removeItem(key),
};

export const Datepad = function (str) {
  return (isNaN(str) ? str : String(str)).padStart(2, 0);
};

export const TimestampToDate = function (timestamp, format, charges, hideHour) {
  let timerobj = {
    YY: "",
    MM: "",
    DD: "",
    hh: "",
    mm: "",
    ss: "",
  };

  if (charges) {
    timerobj = {
      YY: "",
      MM: "",
      DD: "",
      hh: parseInt((timestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      mm: parseInt((timestamp % (1000 * 60 * 60)) / (1000 * 60)),
      ss: (timestamp % (1000 * 60)) / 1000,
    };
  } else {
    if (!timestamp) {
      return (
        "收到：【错误的时间戳或时间格式 " +
        Object.prototype.toString.call(null, timestamp) +
        "】"
      );
    }
    let n_tt = Number(timestamp);
    let date = timestamp instanceof Date ? timestamp : new Date(n_tt);
    timerobj = {
      YY: date.getFullYear(),
      MM: Datepad(date.getMonth() + 1),
      DD: Datepad(date.getDate()),
      hh: Datepad(date.getHours()),
      mm: Datepad(date.getMinutes()),
      ss: Datepad(date.getSeconds()),
    };
  }

  if (hideHour) {
    timerobj.hh == "00" &&
      (function () {
        let hIndex = format.indexOf("hh");
        let mIndex = format.indexOf("mm");
        let formatArr = format.split("");
        formatArr.splice(hIndex, mIndex - hIndex);
        format = formatArr.join("");
      })();
  }

  return Object.keys(timerobj).reduce(
    (formater, cur) => formater.replace(cur, timerobj[cur]),
    format
  );
};

/**
 * 获取uuid
 */
export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    return (c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8").toString(
      16
    );
  });
}

export const getHost = function () {
  let host;
  if (process.env.NODE_ENV == "production") {
    host = "https://gw.center.xcuniv.com";
    // host = "https://gw.center.xcuniv.com";
  } else {
    host = "https://gw.test.center.xcuniv.com";
  }
  return host;
};

export const getRole = function () {
  // 平台设置 start
  // 根据命令自动 打包对应平台 这里不要动
  // 不会对开发环境造成影响
  // if (isT) return "teacher";
  // if (isS) return "student";
  // 平台设置 end
  return window.SUN_LOCAL_ROLE || localStorage.getItem("handRole");
  return "teacher";
};
