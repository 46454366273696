<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script>
//引入vue方法
import { ElConfigProvider } from "element-plus";
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";
//引入自定义方法
//引入自定义组件
export default {
  name: "ZhProvider",
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  setup() {
    let locale = zhCn;
    return {
      locale,
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  flex-direction: column;
  background-color: $bg_grey;
  position: relative;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.beautibar::-webkit-scrollbar {
  width: 0;
  height: 1px;
}
.beautibar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #2d70f8;
}
.beautibar::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #eee;
}
</style>
