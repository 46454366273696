<!--  -->
<template>
  <div class="layout_wrap">
    <Menu/>
    <div class="layout_content">
      <router-view/>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
export default {
  data () {
    return {
      name: ''
    };
  },

  components: {
    Menu
  }
}

</script>

<style lang="scss" scoped>
  .layout_wrap {
    background-color: $white;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    
    .layout_content {
      width: 1200px;
      margin: 0 auto;
      padding-top: 80px;
    }
  }
</style>