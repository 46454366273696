<template>
  <!-- 包含用户信息的菜单 -->
  <div class="user_info_menu">
    <div class="content">
      <dl class="user_info">
        <dt class="img" @click="gotoIndex">
          <img :src="userInfo.headUrl ? userInfo.headUrl : headImg" alt="" />
        </dt>
        <dd>
          <h4 class="name">{{ userInfo.realName }}</h4>
          <span class="role">{{ role }}</span>
        </dd>
      </dl>

      <div class="user_info_menu_btns">
        <!-- <el-button @click="createClass" v-if="role === 'teacher'">
          <i class="el-icon-plus"></i>
          创建班级
        </el-button> -->
        <el-button @click="refresh">
          <i class="el-icon-refresh"></i>
          刷新
        </el-button>
        <el-button @click="signOut">
          <i class="el-icon-switch-button"></i>
          退出</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { getRole } from "@/utils/utils";
import { logout } from "@/server/login";
export default {
  data() {
    return {
      userInfo: {},
      role: "",
      roleType: getRole(),
      headImg: require("../assets/images/header1.png"),
    };
  },
  methods: {
    gotoIndex() {
      sessionStorage.removeItem("stuTabIndex");
      const path =
        this.roleType === "teacher" ? "/teachlogin" : "/studentlogin";

      this.$router.push({ path });
    },
    // 添加课程
    createClass() {
      this.$router.push({
        path: "/createClass",
      });
    },
    // 刷新
    refresh() {
      this.$router.go(0);
    },
    // 退出
    signOut() {
      this.$confirm("是否确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logout().then(() => {
            console.log("退出清理");
            sessionStorage.clear();
            localStorage.clear();
            // localStorage.clear("loginInfo");
            // localStorage.clear("access_token");
            // localStorage.clear("role");
            // localStorage.clear("handRole");
            this.$router.replace({
              path: "/login",
            });
          });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
      // this.$confirm('是否确定退出登录？', '是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     logout().then(() => {
      //         sessionStorage.clear();
      //         this.$router.replace({
      //           path: '/login'
      //         })
      //       })
      //   }).catch(() => {
      //   });
    },
  },
  created() {
    const ROLE_CONFIG = {
      student: "学员",
      teacher: "讲师",
    };
    this.userInfo =
      JSON.parse(sessionStorage.getItem("loginInfo")) ||
      JSON.parse(localStorage.getItem("loginInfo"));
    this.role = ROLE_CONFIG[this.roleType];
  },
};
</script>

<style lang="scss" scoped>
.user_info_menu {
  height: 80px;
  background-color: $theme_color;
  color: $white;
  position: fixed;
  width: 100%;
  z-index: 100;

  .content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .user_info {
    display: flex;

    .img {
      width: 48px;
      height: 48px;
      border: 1px solid $white;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 15px;
    }

    img {
      width: 100%;
    }

    .name {
      font-size: 16px;
      font-weight: 400;
      margin-top: 7px;
      // line-height: 28px;
      margin-bottom: 4px;
    }

    .role {
      font-weight: 400;
    }
  }

  .user_info_menu_btns {
    .el-button {
      font-size: 16px;
      padding: 0;
      height: auto;
      background: none;
      color: $white;
      border: none;
      margin-left: 40px;
    }
  }
}
</style>
