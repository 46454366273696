<template class="user_info_layout">
  <UserInfoMenu />
  <div class="user_info_layout_content">
    <router-view/>
  </div>
</template>
<script>
import UserInfoMenu from '@/components/UserInfoMenu'
export default {
  components: {
    UserInfoMenu
  }
}
</script>

<style lang="scss">
  .user_info_layout_content {
    flex: 1;
    overflow-y: auto;
    width: 1200px;
    margin: 0 auto;
    padding-top: 80px;
    // background-color: $white;
  }
</style>