import './iconfont'
import './tag'

const req = require.context('./svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)


// const svgFiles = require.context('./svg', true, /\.svg$/)
// svgFiles.keys().map(item =>  {
//     svgFiles(item)
// })

// const svgFiles = require.context('./svg', true, /\.svg$/)
// svgFiles.keys().map(item => svgFiles(item))
