import { sms_send, loginApi, logoutApi, role,wechatQrCode,wechatLogin,wechatBind } from './api/login'
import http from '../utils/request';

// 登录
export const login = (data) => {
  return http.post(loginApi, data)
}

// 发送短信
export const sendMessage = (data) => {
  return http.get(sms_send, {params: data})
}

// 退出
export const logout = () => {
  return http.post(logoutApi)
}

// 获取用户角色
export const getUserRole = () => {
  return http.post(role)
}

//微信登录-获取登录扫描二维码
export const getWechatQrCode = (data) => {
  return http.get(wechatQrCode, {params: data})
}

//微信登录-获取token
export const getWechatLogin = (data) => {
  return http.post(wechatLogin, data)
}

//微信绑定
export const wxlogin = (data) => {
  return http.post(wechatBind, data)
}



