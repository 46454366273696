<template name="menu">
  <div class="menu_wrap" :style='"background:"+bgColor'>
    <div class="content">
      <img v-if="this.$route.path === '/selftest' && this.$route.query.type !== 'wrongQs'" src="../assets/images/selftest.png">
      <img v-else-if="this.$route.path === '/examination'" src="../assets/images/examination.png">
      <h3>{{ title }}</h3>
      <el-button v-if="isShowRefresh" class="refresh" @click="refresh">
        <i class="el-icon-refresh"></i>
        刷新
      </el-button>
      <i class="el-icon-close close" @click="closeTest" v-if="test"></i>
      <i class="el-icon-close close" @click="close" v-else></i>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: '',
      isShowRefresh: false
    }
  },
  props: ['menuTitle','test','bgColor','type','gradeId','gradeConfId'],
  watch: {
    menuTitle() {
      this.title = this.menuTitle
    }
  },
  methods: {
    close() {
      console.log(this.type)
      if(!this.type){
        if(this.$route.path !== '/studentDetails') {
          this.$router.go(-1)
        } else {
          this.$router.push({
            path: '/studentlogin'
          })
        }
      }else{
         this.$router.push({
          path: '/studentDetails',
          query: {
            id: this.gradeId,
            gradeConfId: this.gradeConfId
          }
        })
      }
    },
    closeTest(){
      this.$emit('closeTest')
    },
    refresh() {
      this.$router.go(0)
    },
  },
  created() {
    this.title = this.menuTitle || this.$route.meta.title
    if (this.$route.path == "/studentDetails" || this.$route.path == "/details") {
      this.isShowRefresh = true
    }
    console.log(this.$route)
  }
}
</script>

<style lang="scss" scoped>
  .menu_wrap {
    position: fixed;
    width: 100%;
    top: 0;
    height: 80px;
    background-color: $theme_color;
    z-index: 100;

    .content {
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      color: $white;
      text-align: center;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      h3 {
        font-size: 18px;
        font-weight: 400;
        margin-left: 14px;
      }
    }

    .close {
      position: absolute;
      right: 0;
      width: 34px;
      height: 34px;
      background-color: rgba($color: $white, $alpha: .2);
      text-align: center;
      line-height: 34px;
      font-size: 18px;
    }
    .refresh, .el-button{
      position: absolute;
      right: 80px;
      width: 34px;
      font-size: 16px;
      padding: 0;
      height: auto;
      background: none;
      color: #fff;
      border: none;
      margin-left: 40px;
    }
  }
</style>