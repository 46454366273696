import { createRouter, createWebHashHistory } from "vue-router";
import UserInfoLayout from "@/layout/UserInfoLayout.vue";
import Layout from "@/layout/Layout.vue";

const routes = [
  {
    path: "/studentDetails",
    name: "StudentDetails",
    // component: UserInfoLayout,
    component: () =>
      import(
        /* webpackChunkName: "index" */ "../views/StudentDetails/student.vue"
      ),
    // children: [{
    //   path: "",
    //   component:  () => import(/* webpackChunkName: "index" */ '../views/StudentDetails/student.vue'),
    // }],
    meta: {
      title: "学员端",
    },
  },
  {
    path: "/createClass",
    name: "createClass",
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/CreateClass/index.vue"
          ),
      },
    ],
    meta: {
      title: "编辑班级",
    },
  },
  {
    path: "/details",
    name: "details",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Details/index.vue"),
    meta: {
      title: "班级课程",
    },
  },
  {
    path: "/examinationRecord",
    name: "examinationRecord",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Examination/record.vue"),
    meta: {
      title: "礼仪知识学习考试",
    },
  },
  {
    path: "/timetable",
    name: "timetable",
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/Timetable/index.vue"
          ),
      },
    ],
    meta: {
      title: "课程表",
    },
  },
  ,
  {
    path: "/customTimetable",
    name: "customTimetable",
    component: Layout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/Timetable/customIndex.vue"
          ),
      },
    ],
    meta: {
      title: "课程表",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/User/Login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/examination", //学员端考试会用到这个页面。
    name: "Examination",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/Examination/examination.vue"
      ),
    meta: {
      title: "考试",
    },
  },
  {
    path: "/selftest",
    name: "Selftest",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/Examination/selftest.vue"
      ),
    meta: {
      title: "自测",
    },
  },
  {
    path: "/review",
    name: "Review",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Examination/review.vue"),
    meta: {
      title: "回顾",
    },
  },
  {
    path: "/treview", //老师回顾
    name: "tReview",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/Examination/treview.vue"
      ),
    meta: {
      title: "回顾",
    },
  },
  {
    path: "/testresults",
    name: "Testresults",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/Examination/testresults.vue"
      ),
    meta: {
      title: "考试得分",
    },
  },
  {
    path: "/rankingList",
    name: "RankingList",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/RankingList/rankingList.vue"
      ),
    meta: {
      title: "排行榜",
    },
  },
  {
    path: "/studentlogin",
    name: "StudentLogin",
    component: UserInfoLayout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/StudentLogin/StudentLogin.vue"
          ),
      },
    ],
    meta: {
      title: "学员登录",
    },
  },
  {
    path: "/teachlogin",
    name: "TeachLogin",
    component: UserInfoLayout,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "../views/TeachLogin/TeachLogin.vue"
          ),
      },
    ],
    meta: {
      title: "老师登录",
    },
  },
  {
    path: "/datareport",
    name: "Datareport",
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/Datareport/Datareport.vue"
      ),
    meta: {
      title: "数据报告",
    },
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/index.html",
    redirect: "/login",
  },
  // -------------------------student-----------------------------
  {
    path: "/rankingListStu",
    name: "RankingListStu",
    component: () =>
      import(
        /* webpackChunkName: "RankingListStu" */ "../views/student/RankingList/rankingList"
      ),
    meta: {
      title: "排行榜",
    },
  },
  // 学员陪练
  {
    path: "/practicePartner",
    name: "practicePartner",
    component: () =>
      import(
        /* webpackChunkName: "practicePartner" */ "../views/practicePartner/index"
      ),
    meta: {
      title: "学员陪练",
    },
  },
  // 陪练列表
  {
    path: "/classList",
    name: "classList",
    component: () =>
      import(
        /* webpackChunkName: "practicePartner" */ "../views/practicePartner/classList"
      ),
    meta: {
      title: "智能陪练",
    },
  },
  // 陪练列表2
  {
    path: "/recordList",
    name: "recordList",
    component: () =>
      import(
        /* webpackChunkName: "practicePartner" */ "../views/practicePartner/recordList"
      ),
    meta: {
      title: "智能陪练",
    },
  },
  // 讲师陪练列表
  {
    path: "/teacPracticePartner",
    name: "teacPracticePartner",
    component: () =>
      import(
        /* webpackChunkName: "practicePartner" */ "../views/practicePartner/teacPracticePartner"
      ),
    meta: {
      title: "陪练列表",
    },
  },
  // 讲师-学员陪练列表
  {
    path: "/teacStuPracticePartnerList",
    name: "teacStuPracticePartnerList",
    component: () =>
      import(
        /* webpackChunkName: "practicePartner" */ "../views/practicePartner/teacStuPracticePartnerList"
      ),
    meta: {
      title: "学员陪练列表",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
